
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "EarningsBreakdownModal",
  props: {
    isMentorcast: Boolean,
    overview: Object
  },
  data() {
    return {
      // Earnings details items
      earningsDetails: {}
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  mounted(): void {
    if (this.isMentorcast) {
      this.earningsDetails = {
        type: {
          label: "Mentoring type",
          icon: "mentorice"
        },
        price: {
          label: "Price per seat",
          icon: "seat-block",
          price: true
        },
        seat_reserved: {
          label: "Seats reserved",
          icon: "seat"
        },
        total_amount: {
          label: "Paid by users",
          icon: "seat-price",
          price: true
        },
        mentorice_fee: {
          label: "Mentorice fee",
          icon: "fee",
          percent: true,
          price: true
        },
        donations: {
          label: "Donations ",
          icon: "donations",
          percent: true,
          price: true
        },
        zoom_fee: {
          label: "Mentorice host fee",
          icon: "zoom",
          percent: true,
          price: true,
          condition: () => this.overview?.percentages?.zoom_fee > 0
        }
      };
    } else {
      this.earningsDetails = {
        type: {
          label: "Mentoring type",
          icon: "mentorice"
        },
        paid: {
          label: "Paid by user",
          icon: "seat-price"
        },
        mentorice_fee: {
          label: "Mentorice fee",
          icon: "fee",
          price: true
        },
        donations: {
          label: "Donations ",
          icon: "donations",
          price: true
        }
      };
    }
  }
});
