
import Vue from "vue";
import MentoringSession from "@/components/Session/MentoringSession.vue";
import { mapGetters } from "vuex";
import MentoringSessionLoading from "@/components/Session/MentoringSessionLoading.vue";
import ReportUserModal from "@/components/Modals/ReportUserModal.vue";
import SessionTabs from "@/components/Session/SessionTabs.vue";

export default Vue.extend({
  name: "MentoringSessionPage",
  components: {
    SessionTabs,
    MentoringSession,
    ReportUserModal,
    MentoringSessionLoading
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      session: "session/getSession",
      ratings: "session/getRatings"
    })
  },
  mounted: function(): void {
    this.loading = true;
    this.getSession().then(session => {
      console.log(session);
      if (!session.can_i_have_access) {
        this.$router.push("/sessions");
      }
      const type = session.status !== "completed" ? "mentor" : "session";

      this.$store
        .dispatch("session/GET_RATINGS", {
          id: this.$route.params.id,
          type
        })
        .then(() => {
          this.loading = false;
        });
    });
  },
  methods: {
    getSession() {
      return this.$store.dispatch("session/GET_SESSION", this.$route.params.id);
    }
  }
});
