
import Vue from "vue";
import moment from "moment";
import { InformationData, Session } from "@/types/interfaces";
import LaunchModal from "@/components/Modals/LaunchModal.vue";
import EarningsBreakdownModal from "@/components/Modals/EarningsBreakdownModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentoringSession",
  components: {
    LaunchModal,
    EarningsBreakdownModal
  },
  props: {
    session: {
      type: Object as () => Session
    },
    isCancelled: {
      type: Boolean
    },
    isCompleted: {
      type: Boolean
    }
  },
  data() {
    return {
      mentorAmountEarned: "150.00 USD",
      currentTime: {},
      // Table data
      infoData: [
        {
          name: "start_date",
          label: (this as any).$t("sessions.mentoringSessionInfoDataDate"),
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: (this as any).$t("sessions.mentoringSessionInfoDataTime"),
          icon: "time-square",
          value: null
        },
        {
          name: "price",
          label: (this as any).$t("sessions.mentoringSessionInfoDataPrice"),
          icon: "price",
          value: null
        },
        // {
        //   name: "cancellation_fee",
        //   label: "Cancellation fee",
        //   icon: "3users",
        //   value: null
        // },
        {
          name: "duration",
          label: (this as any).$t("sessions.mentoringSessionInfoDataDuration"),
          icon: "time-circle",
          value: null
        }
      ] as InformationData[],
      socialLinks: [
        (this as any).$t("community.socialLinksTwitter"),
        (this as any).$t("community.socialLinksFacebook"),
        (this as any).$t("community.socialLinksInstagram")
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      overview: "session/getOverview"
    })
  },
  created(): void {
    // Find session information by name
    for (const key of Object.keys(this.session)) {
      const index = this.infoData.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_date") {
          //@ts-ignore
          this.infoData[index].value = moment(this.session[key]).format(
            "Do MMMM yyyy"
          );
          continue;
        }
        if (key === "start_time") {
          //@ts-ignore
          this.infoData[index].value = moment(
            this.session[key],
            "hh:mm:ss"
          ).format("hh:mm A");
          continue;
        }
        this.infoData[index].value = this.session[key as keyof Session];
      }
    }
    if (this.profile.type === "mentor") {
      this.$store.dispatch(
        "session/GET_SESSION_OVERVIEW",
        this.$route.params.id
      );
    }
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    onCertificate() {
      this.$router.push(`/sessions/${this.session.uuid}/certificate`);
    }
  }
});
